.topnav {
    /* overflow: hidden; */
    /* width: 100vw; */
    background-color: #2a243f;
    position: sticky; top: 0;
    z-index: 10;
    border-bottom: 0.5px solid #191622;
}
  
.topnav a{
    float: right;
    display: block;
    color: white;
    text-align: center;
    padding: 0.7em 0;
    text-decoration: none;
    font-size: 1.2em;
}

.topnav-item:hover {
    background-color: white;
    color: black;
    font-weight: bold;
}
  
.brand-name {
    /* background-color: #41B9C3; */
    color: white;
    float: left!important;
    font-weight: bold;
}
  
.topnav .icon {
    display: none;
}

.brand-name{

    float: left;
}

/* equally sized section names */
.topnav a:not(:first-child) {
    width: 6em;
}

@media screen and (max-width: 800px) {

    .topnav a:not(:first-child) {
        display: none;
    }
    .topnav a.icon {
        float: right;
        display: block;
    }
}
    
@media screen and (max-width: 800px) {

    .topnav.responsive {
        position: relative;
    }

    .topnav.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }

    .topnav.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
}