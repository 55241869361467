.aboutUsImg{

    /* width: 98.5vw; */
    /* max-height: 20vh; */
    object-fit: cover;
    background-color: white;
    overflow: hidden;
}

.about-title{

    padding: 0.5em 0;
    margin-bottom: 2em;
    /* background-color: rgb(226, 226, 226); */
    color: white!important;
    border-bottom: 1px solid var(--primary-color);
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.about-heading{

    font-size: 2em;
    padding: 0.2em;
}

.about-us{

    /* background-color: #d1d6a3; */
    background-color: #2a243fd4!important;
    /* color: #2a243f!important; */
    color:white;
}

.aboutUsIntro{

    padding-bottom: 2vh;
}

.aboutUsBody, .aboutUsIntro{

    overflow: hidden;
    margin: 0 auto;
    text-align: justify;
}

.aboutUsBody{

    padding-bottom: 5vh;
}

.memberCard{

    padding-left: 0!important;
    padding-right: 0!important;
    height: auto;
    position: relative;
}

.memberCard:hover .avatarImg{

    transform:rotateY(180deg);
    z-index: -1;
}

.memberCard:hover .cardInverse{

    transform:rotateY(0deg);
}

.memberCard:not(:hover) .cardInverse{

    transform:rotateY(-180deg);
}

.memberCard p{

    text-align: justify;
}

.cardInverse{

    width: 90%;
    margin-left:5%;
    height: calc(100% + 1.5px);
    padding-left: 5%;
    box-sizing: border-box;
    position: absolute;
    border: solid 7.5px black;
    border-radius: 14px;
    top: 0;
    background-color: #41B9C3;
    color:var(--text-color-dark);
    
    transition-property: transform;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
}

.avatarImg{

    width: 90%;
    margin-left:5%;
    border-radius: 10px;
    position: relative;
    z-index: 1;

    transition-property: transform, z-index;
    transition-duration: 0.6s, 0s;
    transition-timing-function: ease-in-out, linear;
    transition-delay: 0s, 0.3s;
}

.memberName{

    font-weight: bold;
    font-size: 1.2em;
    padding-top: 1%;
    padding-bottom: 0.5%;
    position: absolute;
    top: 0;
    z-index: 0;
}

.memberBio{

    position: absolute;
    margin-top: 2%;
    margin-right: 5%;
    font-size: 1em;
    top: 30px;
    z-index: 0;
}

@media(max-width: 576px){
    
    .about-heading{

        font-size: 1.5em;
    }

    .memberBio{

        font-size: 0.8em;
    }
}