.mantras-heading{

    color: #2a243f;
    font-size: 2em;
    padding: 0.2em;
}

.mantra{

    background: url("../images/background.png") -10px -20px repeat;
    /* background-color: rgb(226, 226, 226); */
    background-color: white;
    padding-bottom: 5vh;
}

.mantra-title{

    padding: 0.5em 0;
    margin-bottom: 2em;
    /* background-color: rgb(226, 226, 226); */
    background-color: white;
    border-bottom: 1px solid lightgray;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.accordion-item{

    padding: 0!important;
    margin: 5px 0px!important;
    border: none!important;
    border-radius: 10px!important;
    background-color: #2a243fd4!important;
    color: white!important;
}

.accordion-button:focus {

    border-color: rgba(255, 255, 255, 0)!important;
    box-shadow: none!important;
}

.accordion-button{

    border-radius: 10px!important;
    background-color: #41B9C3!important;
    color: #2a243f!important;
    font-weight: 700!important;
}

.ourMissionLetter {

    color: black;
    margin: 0 auto;
    padding-top: 2%;
    padding-left: 12px;
    padding-right: 12px;
}

.accordion{

    margin: 0px!important;
    text-size-adjust: auto!important;
}

@media(max-width: 576px){

    .mantras-heading{

        font-size: 1.5em;
    }
}