.hero-img{
	max-height: 829px;
	max-width: 768px;
}

.hero-background{

	background-color: #2a243f;
	padding-bottom: 10vh;
	padding-left: 10vw;
	padding-right: 10vw;
}

.hero-brand-name{

	color: #41B9C3;
	font-size: 5em;
	line-height: 100%;
}