.games-heading{
    color: #2a243f;
    font-size: 2em;
    padding: 0.2em;
}

.ourGamesSection{

    background: url("../images/background.png") -10px -20px repeat;
}

.ourGamesBody{

    padding: 50px 0 70px 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.projectCard{

    position: relative;
    height: auto;
    text-align: center;
    padding: 0!important;
    border-radius: 10px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    image-rendering: pixelated;
    image-rendering: crisp-edges;  
}

.tape-bottom, .tape-top{

    transition: transform 0.3s ease-in-out, opacity 0.3s 0s ease-in-out;
}

.card-a{

    background-image: url("../images/games/helmet2.png");
}

.card-b{

    background-image: url("../images/games/scihelmet2.png");
}

.projectCard:hover .tape-top{

    transform: translate(0, -80px);
    opacity: 0;
}

.projectCard:hover .tape-bottom{

    transform: translate(0, 80px);
    opacity: 0;
}

.projectCard:hover .card-text{

    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9);
}

.projectTitle{

    height: 50vh;
    font-size: 3em;
    line-height: 1em;
    font-weight: 600;
}

.card-text{

    position: absolute;
    padding-top: 20%;
    top: 15%;
    left: 15%;
    width: 70%;
    height: 70%;
    border-radius: 10px;
    border: 4px solid #41B9C3 ;
    text-align: center;
    opacity: 0;
    transition: opacity 0.5s, background-color 0.5s;
}

.test-img{

    width:100%;
    height:100%;
    image-rendering: pixelated;
    image-rendering: crisp-edges;  
}

.project-description{

    line-height: 70px;
    color: grey;
}

@media(max-width: 576px){

    .projectTitle{

        font-size: 2em;
    }

    .games-heading{

        font-size: 1.5em;
    }

    .ourGamesBody{

        padding-top: 0px;
        padding-bottom: 30px;
    }
}