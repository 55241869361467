@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

:root{

    --primary-color: #2a243f;
    --secondary-color: #2a243fd4;
    --accent-color: #41B9C3;

    --text-color-dark: #2a243f; 
    --text-color-light: white;
}

body {

	margin: 0;
    overflow-x: hidden;
    width: 100%;
}

*{
    font-family: 'Poppins', sans-serif;
}

.anchor{

    scroll-margin-top: 4em;
}
      
.text {

    font-size: 2em;
    text-align: center;
    color: white;
}