.footer{

    /* width: 100vw; */
    background-color: #2a243f;
    color: white;
    padding-bottom: 20px;
    padding-top: 20px;
}

.footer a{

    color:white!important;
}

.footer a:hover{

    color:var(--accent-color)!important;
}

.address{

    margin-bottom: 10px;
}

.footer svg{

    margin-right: 10px;
}